import React from 'react'
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaInstagram, FaKaggle, FaLinkedinIn, FaReact, FaPython, FaGithub, } from "react-icons/fa";
import { SiPytorch, SiTensorflow } from "react-icons/si";

const LeftBanner = () => {
    const [text] = useTypewriter({
      words: ["ML Enthusiast.", "Web Developer.", "AI Developer."],
      loop: true,
      typeSpeed: 20,
      deleteSpeed: 10,
      delaySpeed: 2000,
    });
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">WELCOME TO MY WORLD</h4>
        <h1 className="text-6xl font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize">Risheendra MN</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
        I Thrive on untangling complexities and relish challenges. Adept AI Developer and proven leader, spearheaded a top 7 finish in
an international AI Challenge and founded Zlern, showcasing talent development and crisis management. Expertise in Linux
and web design, projects like the trading bot development, highlight innovative solutions and strategic growth, achieving
substantial business insights.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me in
          </h2>
          <div className="flex gap-4">
            

            <a href="https://www.kaggle.com/risheendra/" target="_blank" rel="noopener noreferrer">

            <span className="bannerIcon">
              <FaKaggle />
            </span>
            </a>

            <a href="https://www.linkedin.com/in/risheendra/" target="_blank" rel="noopener noreferrer">

            <span className="bannerIcon">
              <FaLinkedinIn />
            </span>
            </a>
            <a href="https://www.instagram.com/professor_risheendra/" target="_blank" rel="noopener noreferrer">
              <span className="bannerIcon">
                <FaInstagram />
            </span>
            </a>
            <a href="https://github.com/rishindra2005" target="_blank" rel="noopener noreferrer">
              <span className="bannerIcon">
                <FaGithub />
            </span>
            </a>

          </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL ON
          </h2>
          <div className="flex gap-4">
          <a href="https://www.python.org/" target="_blank" rel="noopener noreferrer">
            <span className="bannerIcon">
              <FaPython />
            </span>
            </a>
            <a href="https://www.tensorflow.org/" target="_blank" rel="noopener noreferrer">

            <span className="bannerIcon">
              <SiTensorflow />
            </span>
            </a>
            <a href="https://pytorch.org/" target="_blank" rel="noopener noreferrer">

            <span className="bannerIcon">
              <SiPytorch />
            </span>
            </a>
            <a href="https://react.dev/" target="_blank" rel="noopener noreferrer">

            <span className="bannerIcon">
              <FaReact />
            </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftBanner