import React from 'react'
import {  FaGlobe } from "react-icons/fa";
import {  SiChatbot  } from "react-icons/si";
import { MdLinkedCamera ,MdOnlinePrediction,MdCandlestickChart  } from "react-icons/md";
import { TbFileTextAi } from "react-icons/tb";

import Title from '../layouts/Title';
import Card from './Card';

const Features = () => {
  return (
    <section
      id="features"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <Title title="Features" des="What I Do" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          title="LLM Development"
          des="Providing advanced language model development services to create intelligent, 
          conversational AI solutions for diverse applications."
          icon={<TbFileTextAi />}
        />
        <Card
          title="Vision AI"
          des="Offering cutting-edge computer vision solutions to analyze, interpret, 
          and understand visual data for diverse applications."
          icon={<MdLinkedCamera  />}
        />
        <Card
          title="GPT Agent"
          des="Providing advanced LLM agent services using Google and OpenAI APIs 
          for intelligent, automated conversational solutions."
          icon={<SiChatbot  />}
        />
        <Card
          title="AI Solutions"
          des="Delivering AI-driven predictive solutions to analyze data and forecast 
          trends for informed decision-making."
          icon={<MdOnlinePrediction />}
        />
        <Card
          title="Trading robot"
          des="Developing advanced trading bots for binary options using the Martingale 
          strategy to enhance trading performance."
          icon={<MdCandlestickChart  />}
        />
        <Card
          title="Web Development"
          des="Providing expert web development services to create dynamic, responsive, and user-friendly websites for diverse needs."
          icon={<FaGlobe />}
        />
      </div>
    </section>
  );
}

export default Features