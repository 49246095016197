import React from 'react'
import Title from '../layouts/Title'
import { projectOne, projectTwo, projectThree,projectFour, projectFive, projectSix,} from "../../assets/index";
import ProjectsCard from './ProjectsCard';
import ProjectsCard1 from './ProjectCard1';


const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title=""
          des="My Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Phnemonia Detection AI"
          des=" AI-powered pneumonia detection using CT scans and U-Net model 
          for precise, early diagnosis and treatment."
          src={projectOne}
          git="https://colab.research.google.com/drive/1FYF55RI-OXZYT8iNwDA4lXQRX9uievtR"
          link="https://drive.google.com/file/d/1PHTeL9pj0pXsmCEWp2nch9_lfzNTQ1Nb/view?usp=sharing"
        />
        <ProjectsCard
          title="Image Aesthetics detector"
          des=" AI tool using CLIP vision encoder and custom layers to rate
           image aesthetics for social media, enhancing visual appeal."
          src={projectTwo}
          git="https://colab.research.google.com/drive/1eGrx9X_VwErGz_JvlS9tZVN8kDoh8P7T"
          link="https://youtu.be/YjR1SobHNyc?si=UxZu5CfZMGzr7PjD"
        />
        <ProjectsCard1
          title="Trading Bot"
          des="Developing advanced trading bots for binary options using the Martingale 
          strategy to enhance trading performance"
          src={projectThree}
          git="https://github.com/rishindra2005/Binary-bot"
        />
        <ProjectsCard1
          title="Bank deposit Predictor AI"
          des="AI model predicting bank deposits, helping financial institutions 
          forecast trends, optimize strategies, and enhance customer service and satisfaction."
          src={projectFour}
          git="https://www.kaggle.com/code/risheendra/bank-deposit-prediction-final-team-bit-by-bit/notebook"
        />
        <ProjectsCard1
          title="Tweet popularity Predictor"
          des=" AI model predicting the popularity of news tweets, helping 
          media outlets optimize content strategy and maximize audience engagement."
          src={projectFive}
          git="https://www.kaggle.com/competitions/news-popularity-ml-thon-2021/leaderboard?tab=public"
        />
        <ProjectsCard1
          title="Energy consumption Predictor"
          des="AI model predicting city energy consumption using SVM and other regressors for efficient energy management."
          src={projectSix}
          git="https://www.kaggle.com/code/risheendra/energy-prediction-of-a-city?scriptVersionId=188279686"
        />
      </div>
    </section>
  );
}

export default Projects